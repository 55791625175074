import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

const cookieName = 'bookmark_app'

// Connects to data-controller="bookmark-app"
export default class extends Controller {
  connect() {
    if (Cookies.get(cookieName)) {
      this.hide()
    } else {
      this.show()
    }
  }

  close() {
    Cookies.set(cookieName, 'done')
    this.hide()
  }

  hide() {
    this.element.style.display = 'none'
  }

  show() {
    this.element.style.display = null
  }
}
