import { application } from './application'

import FeatherController from './feather_controller'
import NavbarController from './navbar_controller'
import TestimonialSliderController from './testimonial_slider_controller'
import BSCollapseController from './bs_collapse_controller'
import PhoneNumberInputController from './phone_number_input_controller'
import BookmarkAppController from './bookmark_app_controller'

application.register('feather', FeatherController)
application.register('navbar', NavbarController)
application.register('bs-collapse', BSCollapseController)
application.register('phone-number-input', PhoneNumberInputController)
application.register('testimonial-slider', TestimonialSliderController)
application.register('bookmark-app', BookmarkAppController)
