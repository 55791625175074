import { Controller } from "@hotwired/stimulus"
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.de'

// Connects to data-controller="phone-number-input"
export default class extends Controller {
  connect() {
    this.cleave = new Cleave(this.element, {
      prefix: '+49',
      phone: true,
      phoneRegionCode: 'DE',
    })
  }

  setRawValue(value) {
    this.cleave.setRawValue(value)
  }

  disconnect() {
    this.cleave.destroy()
  }
}
