import 'cookieconsent'
import Cookies from 'js-cookie'
import once from 'lodash/once'
import defer from 'lodash/defer'

const I18n = {
  t(key) {
    const de = {
      'cookie_consent.policy': "Cookie-Richtlinie",
      'cookie_consent.message': "Mit Klick auf den Button erlauben Sie uns die User Experience auf unserer Website zu verbessern. Sie können diese Entscheidung jederzeit ändern.",
      'cookie_consent.allow': "Ja, ich bin einverstanden",
      'cookie_consent.deny': "Sämtliche Datennutzung ablehnen",
      'cookie_consent.link': "Details",
    }
    return de[key]
  }
}

let isTrackingActive = false

const onConsent = once(function () {
  const unixMilliseconds = (new Date()).getTime()
  // See app/controllers/concerns/cookie_consent.rb
  Cookies.set('cookieconsent_time', Math.floor(unixMilliseconds / 1000))
  Cookies.set('cookieconsent_status', 'allow')
})

const cookieConsent = () => {
  return new Promise((resolve) => {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#000000",
          text: "#ffffff"
        },
        button: {
          background: "#16b00a",
          text: "#ffffff"
        }
      },
      location: false,
      revokable: true,
      theme: "classic",
      type: "opt-in",
      content: {
        policy: I18n.t('cookie_consent.policy'),
        message: I18n.t('cookie_consent.message'),
        allow: I18n.t('cookie_consent.allow'),
        deny: I18n.t('cookie_consent.deny'),
        link: I18n.t('cookie_consent.link'),
        href: "/privacy-policy"
      },
      onInitialise: function (state) {
        isTrackingActive = (state === "allow");
      },
      onStatusChange: function (state) {
        // ahoy.track("Cookie consent status change", {state: state});

        if (state === "allow") {
          onConsent()
        } else if (isTrackingActive) {
          defer(() => location.reload())
        }
      },
      onRevokeChoice: function () {
        // ahoy.track("Cookie consent revoke choice");
      },
    }, (popup) => {
      resolve(() => {
        popup.setStatus(cookieconsent.status.dismiss);
      })
    })
  })
}

export default cookieConsent
